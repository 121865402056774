import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Template } from '../templates/Template';
import ServerStatusCard from '../organisms/ServerStatusCard/ServerStatusCard';
import { Instagram } from '../organisms/SocialMediaEmbeds/Instagram';
import { Twitter } from '../organisms/SocialMediaEmbeds/Twitter';

//TODO fix twitter embed
export const Status = (props) => {
    useEffect(() => {
        //Call API
    })
    return(
        <Template>
            <Helmet>
                <title>[null.black] Server Status</title>
                <meta
                    name="description"
                    content="Monitor downtime, outages, or maintenance to the null.black VPN service."
                />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="English" />
                <meta name="revisit-after" content="14 days" />
                <meta name="author" content="https://null.black" />
                <link rel="canonical" href="https://null.black" />
            </Helmet>
            <header className="container">
                <h1>Server Status</h1>
                <p>We take pride in the high uptime and availability of our service. The chart below shows our current service status, including any outages or ongoing issues. If you are encountering an issue with one or more of our locations and do not see an outage displayed here, please <a href={`mailto:${window.GLOBALS.email}?subject=Inquiry from website`}>contact us</a> immediately.</p>
            </header>
            <main className="container">
                <ServerStatusCard header />
                <div className="Status-socials">
                    <Instagram /> <Twitter />
                </div>
            </main>
        </Template>
    );
}
export default Status;
import React from "react";
import { useState, useEffect } from "react";
import Services from "../../../Services";
import Input from "../../molecules/Input/Input";


export const Captcha = (props) => {
    const [captchasrc, updateCaptcha] = useState("");
    const loadCaptcha = () => {
        return Services.getCaptcha(props.service)
        .then(r => r.json())
        .then(r => {
            if (r.display) {
                return updateCaptcha(r.captcha);
            } else {
                return updateCaptcha("")
            }
        })
        .catch(props.onError)
    }
    useEffect(loadCaptcha, [props]);
    //TODO Test switching between different screens (services), validate captchas actually work

    //TODO I don't even know how we'd do max tries if React re-renders the component every time you submit anyway. We retrieve a new captcha every time regardless. 
    if (captchasrc) {
        return(
        <>
            <img className="Captcha-image" width={220} height={120} src={`data:image/png;base64,${captchasrc}`} alt="Captcha" />
            <Input type="text" label="Enter the text from the image" inputAttributes={{ ...props.inputAttributes, name: "captchaResponse", placeholder: "Text", autocomplete: "off" }} />
        </>)
    } else {
        return null;
    }
}
export default Captcha;

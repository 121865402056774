import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";

import Services from "../../Services";

import Template from "../templates/Template";
import Card from "../organisms/Card/Card";
import ServerStatusCard from "../organisms/ServerStatusCard/ServerStatusCard";
import Button from "../molecules/Button/Button";

import './Dashboard.scss';
//TODO Deal with showing available codes after being directed here from a gift card purchase
//TODO Dialog for redeeming codes
//TODO Welcome dialog which appears after being directed here from register

export const Dashboard = (props) => {
    let [userInfo, updateUserInfo] = useState({username: "User", expiry: 0, will_renew: false, vendor: "none"});
    let [error,setError] = useState(""); //TODO: hotfix, hacky as fuck
    useEffect(() => {
        Promise.all([
            Services.getUser()
            .then(r => {
                if (r.ok) {
                    return r.json().then(d => {
		                console.log("resolving getuser with username", d.username);
                        /* Capitalize first letter of username */
                        return ({username: d.username.charAt(0).toUpperCase() + d.username.slice(1)})
                    })
                } else {
                    throw new Error("An error was encountered while trying to access the User Info API - " + r.status + " " + r.statusText)
                }
            }),
            Services.getUserBilling()
            .then(r => {
                if (r.ok) {
                     return r.json().then(d => {
			console.log("got response in billing: - resolving soon",d);
                        switch (d.vendor) {
                            case "revenuecat":
                                return ({ valid: true, expiry: d.vendor_details.expiration_at * 1000, will_renew: true, vendor: d.vendor });
                            case "paddle":
                                return ({ valid: true, expiry: d.vendor_details.next_bill_date * 1000, will_renew: true, vendor: d.vendor });
                            case "other":
                                //TODO Not sure if this should include the credits field or not. Might be a good thing to include.
                                return({ valid: true, expiry: d.vendor_details.end_date * 1000, will_renew: false, vendor: d.vendor })
			    case "none": 
			        return({ valid:false })
			    default:
			        return({ valid:false })
                        }
                    })
                } else if(r.status === 401) {
		    //properly clean up old cookie
		    window.location.href = "/logout";	
		} else {
                    throw new Error("An error was encountered while trying to access the User Billing API - " + r.status + " " + r.statusText)
                }
            })
        ])
        .then(apiData => {
            //TODO in the future, we'll store staff level here so the dashboard can display staff-specific UI elements. Possibly also a prompt to verify email.
	        console.log("promise.all resolved with", apiData);
            updateUserInfo(Object.assign(apiData[0], apiData[1]));
        })
        .catch(err => console.log("caught in billing+userinfo call", err));
    }, [])
    if (!window.parseCookie().UserSesh) {
	    console.log("no cookie found - sending to login");
	    return(<Redirect to="/home/login" />);
    }

    return(
    <Template>
        <Helmet>
            <title>[null.black] Dashboard | Manage Your VPN Service</title>
            <meta
                name="description"
                content="Manage your null.black VPN service from our easy-to-use dashboard."
            />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="60 days" />
            <meta name="author" content="https://null.black" />
            <link rel="canonical" href="https://null.black" />
        </Helmet>

        <header className="container">
            <h1>Welcome, {userInfo.username}</h1>
        </header>
        <main className="container">
            <section className="Dashboard">
                <h2>Your Plan Details</h2>
                <PlanDetail userInfo={userInfo}/>
                <ServerStatusCard header />
            </section>
        </main>
    </Template>
    );
}

function PlanDetail({userInfo}) {
    //TODO change this for localization
    //This shit has a surprising level of complexity https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
    const endDate = new Intl.DateTimeFormat('en-US', {month: 'short', day: 'numeric', year: 'numeric'}).format(userInfo.expiry);
    const daysLeft = Math.ceil((userInfo.expiry - Date.now()) / (1000*60*60*24));
    return (<Card title="Plan">
                <div className="Dashboard-card-content">
                    <span className="Dashboard-card-content-header">
                            { userInfo.valid && (<> 
                            {/*  Get days between now and expiry */}
                            <span className={`h2 ${(daysLeft < 30) ? ((daysLeft < 7) ? "text--danger" : "text--caution") : ""}`}>{daysLeft} days left&nbsp;</span>
                            <span>({(userInfo.will_renew ? "renews" : "ends")} {endDate})</span>
                        </>) }
                        { !userInfo.valid && (<>
                            <span className="h2 text--caution">A subscription is required to continue.&nbsp;</span><br/>
                            <span>Get a valid subscription to get protected by null.black's network. Plans start at as low as $4.16/month!</span>
                        </>)}
                    </span>
                    <div className="Dashboard-container--button">
                        { /* TODO make language more neutral for non-subscribed users maybe: "Learn about plans" something that represents that they aren't committing to anything by clicking the button 
                        
                        TODO We also need to conditionally change URLs here a lot. Whether or not they have an active plan and therefore which plan we should direct them to purchase, etc.
                        */}
                        <Button href="/dashboard/purchase?plan=monthly&gift=false">{userInfo.valid ? "Switch" : "Purchase"} Plan</Button>
                        { userInfo.valid && <Button href="/download">Download App</Button> }
			    { /* <Button href="/dashboard/purchase?plan=monthly&gift=true">Gift Subscription</Button> */ }
                        {/* Only allow redeeming gifts if we're not on a payment gateway vendor */}
                        { (userInfo.vendor === "other" || userInfo.vendor === "none") && <Button href="/dashboard/redeem">Redeem Gift</Button> }
                    </div>
                    <div className="Dashboard-container--link">
                        <Link to="/article/how-to-connect">How to Connect</Link>
                        <button className="lookLikeLink" onClick={window.GLOBALS.openSupportChat}>Need Help?</button>
                        {/* <Link to="/purchase/cancel">Cancel Service</Link> TODO This should be shown and/or link to different things conditional upon the vendor data */}
                    </div>
                </div>
       	    </Card>)
}

export default Dashboard;

import { useEffect } from "react"
export const Paddle = (props) => {
    //TODO DEAR LORD FUCK ME I HATE THIS SO MUCH OH MY GOD AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa
    useEffect(() => {
        window.Paddle.Setup({
            vendor: 103871,
            //vendor: 125070 //Alpha testing
        })
        window.Paddle.Checkout.open({
            method: 'inline',
            product: props.productId,
            email: props.email,
            // country: props.country,
            loadCallback: props.onPaddleLoad,
            successCallback: props.onPaddleSuccess, 
            closeCallback: props.onPaddleClose,
            passthrough: props.token,
            allowQuantity: false,
            disableLogout: true,
            frameTarget: 'Paddle-checkout-container', // The className of your checkout <div>
            frameInitialHeight: 416,
            frameStyle: 'width: 640px; min-width: 300px; max-width: 100%; background-color: transparent; border: none;'    // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
        });
    })
    return(<div className="Paddle-checkout-container"></div>)
}
export default Paddle;

import React from 'react';
// import PropTypes from 'prop-types';
import './Footer.scss';
import { Logo } from './../../molecules/Logo/Logo';
import { Link } from 'react-router-dom';

export const Footer = (props) => {
    return(
    <footer className="Footer">
        <div className="Footer-content container">
            <Logo stack box />
            <address className="Footer-info text--secondary">
                <p>
                    <span className="Footer-info-title h6">null.black Inc.</span>
                    <br />
                    The privacy and security company
                </p>
                <p>
                    <a href="https://cis.scc.virginia.gov/EntitySearch/BusinessInformation?businessId=366451" target="_blank" rel="noopener noreferrer">Entity ID 08501934</a>
                    <br />
                    P.O. Box 131 Paeonian Springs, VA 20129-9997
                    <br />
                    <a href={`mailto:${window.GLOBALS.email}?subject=Inquiry from website`}>{window.GLOBALS.email}</a>
                </p>
                <hr />
                <ul className="Footer-info-list">
                    <li className="Footer-info-list-item">
                        <a href="/twitter" target="_blank" rel="noopener noreferrer">Twitter</a>
                        <span>&nbsp;/&nbsp;</span>
                    </li>
                    <li className="Footer-info-list-item">
                        <a href="/instagram" target="_blank" rel="noopener noreferrer">Instagram</a>
                        <span>&nbsp;/&nbsp;</span>
                    </li>
                    <li className="Footer-info-list-item">
                        <a href="/discord">Discord</a>
                    </li>
                </ul>
                <br />
                <ul className="Footer-info-list">
                    <li className="Footer-info-list-item">
                        <Link to="/privacy">Privacy Policy</Link>
                        <span>&nbsp;/&nbsp;</span>
                    </li>
                    <li className="Footer-info-list-item">
                        <Link to="/terms">Terms of Service</Link>
                    </li>
                </ul>
            </address>
        </div>
    </footer>);
}

export default Footer;
import React from 'react';
import './ModalCard.scss';
import { Link } from 'react-router-dom';
// import LoadingScreen from '../LoadingScreen/LoadingScreen';
export const ModalCard = (props) => {
    return(
        <div className="ModalCard">
            {/* <LoadingScreen visible={props.loading} actionMessage={props.actionMessage} /> */}
            <div className="ModalCard-header">
                <strong className="ModalCard-header-title h2">{props.title}</strong>
                <br />
                <span className="ModalCard-header-subtitle">{props.subtitle}</span>
                <Link to={"/" + new URL(window.location.href).pathname.split('/')[1]}>
                    <span className="ModalCard-header-close h3">&times;</span>
                </Link>
            </div>
            <div className="ModalCard-body">
                {!props.error && props.children}
                {props.error &&
                <div className="ModalCard-error">
                    <span className="ModalCard-error-message">
                        An error occurred{props.actionMessage ? ` while ${props.actionMessage}`:""}. Please try again, or <a href={`mailto:weare@null.black?subject=${props.errorMessage} while ${props.actionMessage}`}>contact us</a> if the issue persists.
                        <br /><br />
                        <p className="text--secondary--mono text--danger">{props.errorMessage}</p>
                    </span>
                </div>}
            </div>
            <div className="ModalCard-footer">
                {props.backBtn}
                {props.submitBtn}
            </div>
        </div>
    )
}

export default ModalCard;
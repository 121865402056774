import { Helmet } from 'react-helmet';
import { useParams, Link, Redirect } from 'react-router-dom';
import Template from '../templates/Template';
import './Article.scss';
//TODO wow this is super lame. Move this to a CMS ASAP
const articleContent = {
    "about-us": {
        title: "About Us",
        content:
        <>
        <Helmet>
            <title>[null.black] About Us</title>
            <meta
                name="description"
                content="null.black is dedicated to two fundamental principles - privacy and security. Find out how you can help us in our mission to stop censorship and protect freedom everywhere."
            />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="30 days" />
            <meta name="author" content="https://null.black" />
            <link rel="canonical" href="https://null.black" />
        </Helmet>
        <article id="mission">
            <h2>Company Mission</h2>
            <p><em>null.black is dedicated to two fundamental principles - privacy and security.</em> We provide our service according to these two values, without compromise. Complete transparency and integrity are very important to us. We will not collect any information on our users beyond what is strictly necessary to operate our service. Our privacy policy lays this out in explicit detail, and we publicize our service log files to ensure transparency.</p>

            <p>Privacy can often be taken for granted in the west, but it’s a rare commodity for many parts of the world. Countries such as Saudi Arabia, China, Cambodia, and Myanmar actively censor the flow of information in and out of the country. We have committed ourselves and our technology to provide citizens under repressive regimes with unrestricted access to information, without the risk of punishment by a fascist government.</p>
        </article>
        <article id="history">
            <h2>Company History</h2>
            <p><em>What does it take to bring down political censorship online?</em> Is activism restricted to those that protest and publish, or is there a way for programmers and technologists to fight malicious censorship? Null.black was first conceptualized in early 2016, when our founder met and befriended two people living under strict political censorship: one behind the infamous “Great Firewall of China”, and another living behind Saudi Arabia’s Ministry of Communications and Information Technology’s strict internet blocks.</p>

            <p>Out of a desire to help those two internet users access blocked political information, a loosely organized community spawned around the success of our software prototype. In the aftermath of the assassination of Washington Post journalist Jamal Khashoggi, some Saudi Arabian residents told us they used our service to read blocked news media that posted about the topic (like Al Jazeera news). Meanwhile, people living in China revealed they used our service to learn about censored historical events, like the events of June 1989.</p>

            <p>Together, we defeated three Distributed Denial of Service attacks, protected a white-hat group while they poisoned a phishing operation by feeding large amounts of incorrect credentials into their database, and helped activists get around their countries censorship to read uncensored political material.</p>

            <p>What makes internet censorship particularly potent is the assumption that the internet contains an infinite source of viewpoints. It's this assumption that makes those under censorship believe that they’ve read and evaluated the whole spectrum of opinions, when in reality they’ve only seen the government-filtered side. As foreign regimes and multinational corporations try to use the internet as a weapon to control and destroy information, null.black pledges to provide our customers and the broader internet with the tools to break free of political censorship.</p>
        </article>
        <aside id="further-reading">
            <h2>Further Reading</h2>
            <Link to="how-a-vpn-works">How a VPN Works and Protects You</Link>
        </aside>
        </>
    },
    "how-to-connect": {
        //TODO Get the screenshots from amanuel from this
        title: "How to Connect",
        content:
        <>
        <Helmet>
            <title>[null.black] How to Connect to Our VPN Service</title>
            <meta
                name="description"
                content="Connecting to null.black VPN is a simple, 4-step process! Just follow this guide and you'll be browsing the web safely, securely, and privately in no time!"
            />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="30 days" />
            <meta name="author" content="https://null.black" />
            <link rel="canonical" href="https://null.black" />
        </Helmet>
        <article>
            <ol>
                <li><Link to="/download">Download the null.black VPN app from the Downloads page</Link>.</li>
                <li>Open the app, then log into your account, or create an account, then log in.</li>
                <li>(Optional) Choose a location to connect to.</li>
                <li>Press the "Connect" button.</li>
                <li>That's it! You're connected to null.black and can securely and privately browse the web.</li>
            </ol>
            <br/>
            <aside>If you encounter any issues getting connected to null.black VPN, please don't hesitate to <a href={`mailto:${window.GLOBALS.email}?subject=Help! I'm having issues with the VPN`}>contact us</a> or <button className="lookLikeLink" onClick={window.GLOBALS.openSupportChat}>get in touch with us via live chat</button>. We're happy to help!</aside>
        </article>
        </>
    },
    "how-a-vpn-works": {
        title: "How a VPN Works and Protects You",
        content:
        <>
        <Helmet>
            <title>[null.black] How a VPN Works - Explained for Non-Programmers</title>
            <meta
                name="description"
                content="Find out how null.black VPN works, explained in simple, non-technical terms to help you understand what our service does and how it protects you online."
            />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="30 days" />
            <meta name="author" content="https://null.black" />
            <link rel="canonical" href="https://null.black" />
        </Helmet>
        <article>
            <h2 id="background">A Bit of Background</h2>
            <p><em>What exactly is a VPN?</em> 
            {/* TODO Finish this paragraph */}
            <br />
            <dfn><abbr title="Virtual Private Network">VPN</abbr></dfn> stands for Virtual Private Network. It was originally intended to allow remote office workers to access the network at their office from anywhere as though they were at their desk, but it has since evolved to also provide enhanced online privacy and security.
            </p>
            <h2 id="technical-details">The Technical Details</h2>
            <p>To understand how a VPN works, it’s important to have a basic grasp of cryptography and the computer science behind it. The most important detail to remember is this: computers only work with binary numbers, which are stored as 1s and 0s. For example, a capital “A” (according to the ASCII standard), or the integer 65 is stored as 1000001 in a computer. Cryptography— and by extension VPNs— work by performing mathematical operations on those binary numbers so the original data is only revealed to the intended recipient. These mathematical operations are highly complex and outside the scope of this article, but suffice to say that this technological breakthrough was made decades ago and its use is nearly ubiquitous to this day.</p>
            <h2 id="key-exchange">The Key Exchange</h2>
            <p>The first step in any transfer of encrypted information is the key exchange. Person A wants to send person B a secure message. Person A has a pair of keys: one to encrypt the message, and one to decrypt it. Any data encrypted with one can only be read using the other in the pair (not even the same key used to encrypt the data can decrypt it). One key is given to Person B by Person A. Now, any message that Person A encrypts with their key can only be read by Person B using their key. By exchanging these keys ahead of time, they become nearly impossible to intercept, and therefore the line of communication is secured.</p>

            <h2 id="helpful-analogy">A Helpful Analogy</h2>
            <p>Think of a VPN as a translator; your message the translator in a language only you and the translator understand, and the translator conveys the message to the intended recipient in a language only they can understand. Even if someone overhears the message, they will not be able to understand it.</p>

            <h2 id="good-news">The Good News</h2>
            <p>Fortunately, most modern websites use SSL, which employs similar technology to hide most of your online activity. However, it’s still possible for ISPs, governments, and other spying parties to see the domain name of the website, the time at which you accessed it, and the total data transfer between you and the website. VPNs can help to enhance your security by passing the encrypted traffic securely through our servers. This hides your true IP address, location, websites you access, and internet service provider from anyone who may be trying to attain such information.</p>
        </article>
        </>
    },
    "terms-of-service": {
        // TODO finish this
        title: "Terms of Service",
        content:
        <>
        <Helmet>
            <title>
                [null.black] Terms of Service
            </title>
            <meta name="description" content="Read about null.black's Terms of Service."/>
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="30 days" />
            <meta name="author" content="https://null.black" />
            <link rel="canonical" href="https://null.black" />
        </Helmet>
            <article>
                <h2 id="intended-purpose">null.black VPN's Intended Purpose</h2>
                <p>null.black VPN is designed to aide people living behind corporate, institutional or foreign governmental firewalls to evade political censorship and access the internet, privately.
                <br/>
                To achieve this, null.black generally places VPN servers in democratic countries that value free speech which may include: The United States, Netherlands, Japan, South Korea, India and some members of the European Union.
                <br />
                null.black's secondary purpose is to reduce the effectiveness of online tracking by acting as an anonmyizer. This reduces the effectiveness of targeted advertising and similar invasive technologies.</p>
                <h2 id="illegal-activities">Illegal Activities</h2>
		<p>
		null.black Inc. is an American company, and therefore follows American law. null.black VPN does not support any illegal activity, including but not limited to pirating, any type of illegal pornography, terrorism, and fraud. Users are expected to comply with US Law.
		<br/>
		null.black Inc. reserves the right to refuse service without refund to any customer that is suspected to be committing illegal activity. Because of the restrictions put in place by our privacy policy, null.black Inc. shall not be compelled to confirm illegal activity to terminate a user on suspicion of comitting illegal activity.
		</p>
                <h2 id="prohibited-activities">Prohibited Activities</h2>
		<p>All Illegal Activites are Prohibited Activities (see Illegal Activities section for definitions and details).
		<br/>
		Use of peer-to-peer technologies is not a prohibited activity so long as no copyright violations are made, that is, the User must confirm that no copyrighted material is being illicitly sent or recieved by their computer through our service.
		<br/>
		All Illegal Activites are Prohibited Activities (see Illegal Activities section for definitions and details).
		</p>
		<p>
		Use of peer-to-peer technologies is not a prohibited activity so long as no copyright violations are made, that is, the User must confirm that no copyrighted material is being illicitly sent or recieved by their computer through our service.
		</p>
		<p>
		Spam, mass-emailing, and heavy automated traffic are also Prohibited Activities. Excessive use or abuse of null.black resources (including server bandwidth, and the website) is also a Prohibited Activity. Excessive use or abuse of other internet or network resources regardless of ownership or control by null.black, in a manner that causes damage or harm to those resources is also a Prohibited Activity. Excessive use is any action or set of actions that cause system slowdowns, reduced performance, or denial of service to any system.
		<br/>
		null.black Inc. reserves the right to refuse service without refund to any customer that is suspected to be committing prohibited activities. Because of the restrictions put in place by our privacy policy, null.black Inc. shall not be compelled to confirm Prohibited Activity to terminate a user on suspicion of comitting prohibited activities.
		</p>
		<h2 id="uptime">Uptime</h2>
		<p>
		null.black Inc. takes great care to ensure the reliability of our VPN servers. If a VPN server goes down, most customers will have the opportunity to connect to a different server. For this reason, a "partial outage" shall not be considered an Outage.
		For the purposes of this section, an Outage shall occur if all available servers go down due to the action or inaction of null.black Inc. for more than 24 hours per month.
		</p>
		<br/>
		<h3>Planned Outages</h3>
		<p>
		In order to provide optimal service to our customers, null.black VPN or a component service may have to be made unavailable to allow for maintainance and new features.
		Customers will be notified of a planned outage through notification on null.black Inc.'s website, social media, or via email notification. This notification will be delivered at least 3 days in advance of the outage and will include a reason for the planned outage, and the duration of the outage. If the outage exceeds the planned duration by more than 25% or 3 days (whichever is longer), the time by which the Outage exceeds the duration shall be considered an unplanned outage.
		</p>
		<br/>
		<h3>Unplanned Outages</h3>
		<p>
		If an unplanned outage occures, affected customers will be provided, upon request, credit in their account in the form of a subscription extention by the length of time that the servers were unavailable.
		If this outage condition persists for more than two weeks per month, null.black's affected customers upon request will be eligible for a full refund for that month's service. Customers who recieve a full or partial refund will not recieve an extention of their account subscription in the same month. Customers who recieve an extention of their account subscription will not be eligible for a refund in the same month.
		</p>
		<p>
		Nothing in this section may be construed to compel null.black to offer refund to customers who are not able to connect to null.black due to a shortcoming or failure in their computing equipment, network connection to null.black VPN, knowledge or software.
		<br/>
		There shall be no refunds, credit, or subscription extentions on any non-production service (i.e. a beta or alpha service). Nothing in this TOS may be construed to guarentee the uptime or downtime of any service marked beta or the alpha.null.black/alpha-rust.null.black service. Nothing in this section shall be construed to require null.black to offer damages, refunds, or restitution for the effects of an outage.
		</p>
		<h2 id="data-accuracy">Data Accuracy</h2>
		<p>
		null.black values the accuracy of the data, information, and support suggestions we give to our customers.
		</p>
		<p>
		On rare occasion, human or computer error may result in data innaccuracy. Support staff are people and therefore are capable of making mistakes. null.black Inc. makes no guarentee regarding the information or assistance provided by support. If support staff do not effectively help you, you may file a complain with their supervisor or with support@null.black.<br/>
		Sometimes data may not be recent or up-to-date due to network or technological conditions. Therefore, null.black makes no guarentee regarding the accuracy or precision of any data, including bandwidth consumption, servers actively connected to, etc.
		</p>
		<h2 id="living-document">Living Document</h2>
		<p>
		We believe that our users should remain educated and informed about the terms of services of the services they use. The latest version of the terms of service will always be available at https://null.black/TOS.<br/>
		If we update the terms of service, we agree to attempt to communicate that a change has been made. The communication may happen over email, social media, or on our website. We suggest following and pay attention to all three platforms so you don't miss any important change.<br/>
		This terms of service was last updated on Sun. August 15 2021.<br/>
		You agree to monitor changes to this terms of service so that you may continue to recieve the benefits of our service.<br/>
		</p>
            </article>
        </>
    },
    "privacy-policy": {
        title: "Privacy Policy",
        content:
        <>
        <Helmet>
            <title>
                [null.black] Privacy Policy | Our Privacy and Security Guarantee
            </title>
            <meta
                name="description"
                content="Read about how null.black, the privacy and security company, protects your information from prying eyes."
            />
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="30 days" />
            <meta name="author" content="https://null.black" />
            <link rel="canonical" href="https://null.black" />
        </Helmet>
        <article>
            <h2>Mission</h2>
            <p>Null.Black's Mission is to create a safe and protected connection to the internet, to help those in places with severe censorship. With that said being said, Null.Black is not a political company, just interested in the classic Enlightenment ideas of Life, Liberty, and Fraternity. This Privacy Policy applies to people using www.null.black and its services ('Users') whether it is purchased directly from Null.Black or from a third-party company.</p>
            <ol className="Privacy">
                <li className="Privacy-group">
                    <h2>Collected Information</h2>
                    <ol className="Privacy-sections">
                        <li className="Privacy-section">
                            <h3>What is Personal Data?</h3>
                            <p>In the US Personal Data is defined as any information relating to an identified or identifiable individual; an identifiable person is one who can be identified, directly or indirectly, in particular by reference to an identification number or one or more factors specific to his physical, physiological, mental, economic, cultural or social identity.
                            <br />
                            The personal information we store include your username, email address and first name.</p>
                        </li>
                        <li className="Privacy-section">
                            <h3>Data Stored</h3>
                            <p>We are pleased to say the only personal data that we store will be your first name and an email address.
                            <br />
                            We also store some non-personal data like total bandwidth consumption and which VPN servers you're actively connected to in order to let you connect all your devices.
                            <br />
                            Support interactions and emails you send us are also stored so we can make sure you're treated with the respect your deserve as a customer.</p>
                        </li>
                        <li className="Privacy-section">
                            <h3>Cookies</h3>
                            <p>We will NEVER use malicious cookies to track you or steal your personal information.
                            <br />
                            Here's an explaination of the cookies we do leave, the privacy protections involved, and what they're used for:
                            </p>
                            <ul>
                                <li>"cap" is a cookie that helps us make sure that the captcha you entered is correct. It corresponds to the correct answer that was used to generate the captcha. This cookie is not shared with third parties, and is set to only be visible to the null.black domain</li>
                                <li>"UserSesh" is a cookie that is securely signed that lets us know that you're logged in when you visit our website. This cookie is not shared with third parties, and is set to only be visible to the null.black domain</li>
                            </ul>
                            <br />
                            <p>LocalStorage is leveraged by our application to keep loads fast, similar to your browser's cache.
                            <br />
                            <br />
                            Also, our support chat service (which is run on our servers) leaves cookies (like cw_conversation and other cookies prefixed with cw_) to help make sure you can have a fluid support experience (so when you start a chat, navigate away, and come back, you can continue that same support chat)</p>
                        </li>
                        <li className="Privacy-section">
                            <h3>How We Use that Information</h3>
                            <p>Any personal data that we do use is only used for critical account activities like during account creation, login, system integrity, TOS enforcement and support. Additionally, we'll send you emails for important things. Nothing else, without your permission.</p>
                            <p>If you opt-in to pay via Paddle, our a third-party billing software, you should review <a href="https://paddle.com/privacy/" target="_blank" rel="noopener noreferrer">their privacy notice</a>. We have been informed that they practice Safe Harbor principles protecting your data.</p>
                            <p>When billing via Paddle, we will share a fully encrypted token (to which Paddle will not have the keys to access). This enables your account to be credited when you make a purchase. Without special encryption keys, Paddle will not know that this token corresponds to your username.</p>
                            <p>Prepaid codes sold by other third parties may have their own privacy policies, please consult your reseller for more information.
                            If someone who authenticates as you requests your personal information, we will release it to them.</p>
                        </li>
                    </ol>
                </li>
                <li className="Privacy-group">
                    <h2>Protections Under Law</h2>
                    <ol className="Privacy-sections">
                        <li className="Privacy-section">
                            <h3>Storage</h3>
                            <p>Null.Black is based in the United States but operates World Wide. Apart from your username (which is used to generate files necessary to permit your connection to foreign VPN servers), your personal information will only be stored inside the United States and is therefore protected under United States' Law.</p>
                            <p>In order to increase support availability, we have an international support team. If you authorize an international support agent to help you with your account, a non-US person may have limited access to your data.</p>
                        </li>
                        <li className="Privacy-section">
                            <h3>Information Originating Outside the United States</h3>
                            <p>
                            If the information originates in the EU we comply with the EU Standard contractual clauses or privacy and the privacy shield clauses used by the US Department of Commerce ( www.privacyshield.gov/welcome ). In the Asian continent, we do not comply with the Chinese Communist Party nor its censorship policies. In South America, we choose to follow Argentina's <a href="http://www.worldlii.org/au/other/AUPrivLRes/2009/62.html" target="_blank" rel="noopener noreferrer">Information Act 2002</a> which states that any information transfer must have consent from the Users. With regards to Africa, we comply with section 14 of the South African Constitution.</p>
                        </li>
		    </ol>
		</li>
                <li className="Privacy-group">
                    <h2>Changes</h2>
                    <ol className="Privacy-sections">
                        <li className="Privacy-section">
                            <h3>Living Document</h3>
                            <p>We believe that our users should remain educated and informed about the privacy policies of the services they use. The latest version of the privacy policy will generally be available at <a href="https://null.black/privacy">https://null.black/privacy</a>.</p>
                            <p>If we update the privacy policy, we agree to attempt to communicate that a change has been made. The communication may happen over email, social media, or on our website. We suggest following and pay attention to all three platforms so you don't miss any important change.</p>
                            <p>This privacy policy was last updated on <time datetime="2021-10-15">Thursday, August 15, 2021</time>.
                            </p>
                            <p>Even though the fundamental use of your data will not change, you agree to monitor changes to this privacy policy so that you may renew consent or terminate your use of our service.</p>
                        </li>
                        <li className="Privacy-section">
                            <h3>Contact</h3>
                            <p>If you have a concern, complaint, or suggestion, a member of our team will be happy to help at <a href={`mailto:${window.GLOBALS.email}?subject=Inquiry from privacy policy`}>{window.GLOBALS.email}</a>, or click the little green bubble to chat with an agent!</p>
                        </li>
                    </ol>
                </li>
            </ol>
        </article>
        </>
    }
}

export const Article = (props) => {
    const content = articleContent[useParams().article];
    if (!content) return(<Redirect to="/" />);
    return(
        <Template>
            <header className="container">
                <h1>{content.title}</h1>
            </header>
            <main className="container">
                <article>{content.content}</article>
            </main>
        </Template>
    )
}

export default Article;

import React from 'react';
import './PriceCard.scss';
import Button from '../../molecules/Button/Button';
import { Link, useRouteMatch } from 'react-router-dom';
const PriceCard = (props) => {
    const url = useRouteMatch().url;
    //TODO wow this sucks too. We could really use a CMS for this.
    const content = {
        yearly: {
            title: "Yearly",
            price: "$4.17",
            subtext:<span className="text--secondary">Billed at $49.99/year</span>
        },
        monthly: {
            title: "Monthly",
            price: "$4.99",
            subtext:<span className="text--secondary">Billed monthly</span>
        }
    }
    const c = (props.yearly ? content.yearly : content.monthly);
    return(
        <div className="PriceCard">
            <ul className="PriceCard-features">
                <li className="PriceCard-features-item h3--bold">{c.title}</li>
                {props.features.map(f => <li key={f} className="PriceCard-features-item text--secondary">{f}</li>)}
            </ul>
            <div className="PriceCard-pricing">
                <b className="PriceCard-pricing-price h1">{c.price}<span className="h4">/mo</span></b>
                <br />
                {c.subtext}
                <br />
                <br />
                <div className="PriceCard-pricing-order">
                    <Button href={`${url}/purchase?plan=${c.title.toLocaleLowerCase()}`}>Order</Button>&nbsp;&nbsp;or&nbsp;&nbsp;<Link to={`${url}/purchase?plan=${c.title.toLocaleLowerCase()}&gift=true`}>Gift</Link> {/* Create an nginx redirect from /gift for this */}
                </div>
            </div>
        </div>
    )
}
PriceCard.defaultProps = {
    features: ["Up to 5 devices", "3 Locations Worldwide", "No Commitment"]
}

export default PriceCard;
import React from 'react';
import { useState, useEffect } from 'react';
import Services from '../../../Services';
import Card from '../Card/Card';
import './ServerStatusCard.scss';

//TODO I don't know if I've implemented this table 100% correctly, should probably go back and check at some point
/**
 * A card displaying a table containing various information about servers and the user's connections to those servers
 * @param props.max {Number} The maximum number of connections a user can have
 */
export const ServerStatusCard = (props) => {
    const [serverData, updateServerData] = useState([]);
    useEffect(() => {
        //TODO Make sure this data ends up in the correct order for both fields - Amsterdam data lines up with Amsterdam location, etc. 
        Promise.all([
            Services.getServerData()
            .then(r => {
                if (r.ok) {
                    return r.json()
                    .then(d => {
                        delete d.status;
			return d;
                    })
                } else {
                    throw new Error("An error was encountered while connecting to the Server Data API - " + r.status + " " + r.statusText);
                }
            }),
            Services.getUserConnections()
            .then(r => {
                if (r.ok) {
                    return r.json()
                    .then(d => {
                        delete d.status;
                        const data = d.conns;
                        console.log("got data",data);
                        return data;
                        /*return({
                            connections: Object.values(data).map(({count, max}) => [count, max]),
                            usage: Object.values(data).map(({overall_kb}) => overall_kb / 1000000)
                        })*/
                    })
                } else {
                    throw new Error("An error was encountered while connecting to the User Connections API - " + r.status + " " + r.statusText)
                }
            })
        ])
        .then(r => {
            //When we've retrieved all the needed data, merge them together into one object and put it in state.
	    // we want an array that contains:
		// objects that have server data
		// these objects also have connection data
	   // servers with active connections from that user should appear first
	    console.log("got server data",r);
	    let servers_and_connections = { };
	    for(const [server_name, server_details] of Object.entries(r[0])) {
		    servers_and_connections[server_name] = {server_info: server_details};
	    }

	    for(const [server_name, connection_details] of Object.entries(r[1])) {
		    servers_and_connections[server_name].connection_info = connection_details;
	    }
	    console.log("collected server data and connections",servers_and_connections);

	    let servers_and_connections_as_entries = [];
        //TODO there's almost certainly an existing JS function for this, use that instead.
	    for(const [server_name, combo_details] of Object.entries(servers_and_connections))
	    {
		    servers_and_connections_as_entries.push({name: server_name, ...combo_details})
	    }
	    servers_and_connections_as_entries.sort((a,b) => {
		    return (b?.connection_info?.count || 0 ) - (a?.connection_info?.count || 0);
	    })
            updateServerData(servers_and_connections_as_entries);
        })
        .catch(err => console.log("could not get server data", err))
    },[])
    

    //TODO dear fucking lord why am I like this
    if (serverData.length === 0) {
        return null;
    }
    return(
    <Card icon={props.icon && <i className="icon icon-windows"></i>} title={props.header && "Server Status"}>
        <table className="ServerStatusCard-table">
            <tbody>
                <tr>
                    <th scope="row">Location</th>
                    {serverData.map(({name, server_info}) => (<td key={name}>{server_info.flag} {name}</td>))}
                </tr>
                <tr>
                    <th scope="row">IP Address</th>
                    {serverData.map(({name, server_info}) => <td key={name}>{server_info.ip}</td>)}
                </tr>
                <tr>
                    <th scope="row">Your Active Devices</th>
	            {serverData.map(({name, connection_info}) => {
                    return (<td key={name}>{connection_info?.count || 0}</td>)
                }) 
		        }
                </tr>
	            <tr>
                    <th scope="row">Uptime</th>
	            {serverData.map(({name, server_info}) => {
			let classname = "";
			let uptime = (server_info.up_intervals / server_info.total_intervals);
			if (uptime > 0.95) {
			    classname = "text--success"
			}
			else if (uptime <= 0.9) {
                classname = "text--caution"
                if (uptime <= 0.8){
                    classname = "text--danger"
                }
            }
            return (<td className={classname} key={name}>{(server_info.up_intervals/server_info.total_intervals*100).toFixed(2)}%</td>)
		    })}
	        </tr>
                <tr>
                    <th scope="row">Usage</th>
                    {serverData.map(({connection_info}) => <td>{(( (connection_info?.download_kb || 0) + (connection_info?.upload_kb || 0) )/1000000).toFixed(2)} GB</td>)}
                </tr>
            </tbody>
        </table>
    </Card>
    );
}

export default ServerStatusCard;

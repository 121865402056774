import { useState } from "react";
import Services from "../../../Services";
import { Redirect, Link, useRouteMatch } from "react-router-dom";

import ModalCard from "./ModalCard"
import Input from "../../molecules/Input/Input";
import Button from "../../molecules/Button/Button";
export const Redeem = (props) => {
    const [badInputMessage, setBadInputMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const handleCodeRedemption = (e) => {
        e.preventDefault();
        return Services.claimCode(Object.fromEntries(new FormData(e.target).entries()))
        .then(r => {
            if (r.ok) {
                return r.json()
                .then(window.location = "/dashboard") //Go on to the next step
            } else {
                return r.json()
                .then(d => setBadInputMessage(d.error))
            }
        })
        .catch(err => setErrorMessage(err))
    }
    // If we're not at the dashboard, return us home
    if (useRouteMatch().url !== "/dashboard/redeem") {
        return <Redirect to="/home" />
    }
    return(
        <ModalCard
        title="Redeem Gift Code"
        subtitle={<>Redeem gift card or <Link to="purchase?plan=monthly&gift=true">purchase a gift card</Link> instead</>}
        error={!!errorMessage}
        errorMessage={errorMessage}
        backBtn={<Button buttonAttributes={{onClick: props.previous}}>Back</Button>}
        submitBtn={<Button type="submit" buttonAttributes={{form: "form", disabled: !!errorMessage}}>Continue</Button>}
        >
            <form id="form" onSubmit={handleCodeRedemption}>
                <Input type="text" label="Enter your gift code" inputAttributes={{name: "code", placeholder: "Gift code", required: true, disabled: !!errorMessage, autoComplete: "off"}} />
            </form>
            {badInputMessage && <span className="ModalCard-body-expected-error text--secondary text--danger">Error: {badInputMessage}</span>}
        </ModalCard>
    )
}

export default Redeem;

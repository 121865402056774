import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import './Dropdown.scss';

//TODO do we need aria-roles for this since it's kind of a custom component?
//This is componentized, but it's still written as a member of Nav (e.g. li parent tag)
export const Dropdown = (props) => {
    const [expanded, toggleExpand] = useState(false);
    return( 
        <div className={`Dropdown expandable ${expanded ? "expanded" : ""}`}>
            <p className="Dropdown-label" onClick={() => toggleExpand(!expanded)}>{props.label}&nbsp;<i className="icon icon-arrow-down"></i></p>
            <ul className="Dropdown-menu">
                {/* Iterate over props.links and generate a link for each item */}
                {Object.entries(props.links).map(([text,url]) => (
                    <li key={text} className="Dropdown-menu-item">
                        <a href={url}>{text}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

Dropdown.defaultProps = {
    label: "Label",
    links: {
        "Plans":"#",
        "Gift Codes":"#",
        "Features":"#",
    }
}
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Services from '../../Services';

import Template from "../templates/Template";
import DownloadCard from "../organisms/DownloadCard/DownloadCard";
import { useState } from 'react';
export const Download = (props) => {
    const [downloads, updateDownloads] = useState([]);
    const [auto, setAuto] = useState(null);
    useEffect(() => {
        Services.getDownloads("null.black_VPN")
        .then(d => {
            //Set state to contain all the apps' data
            return d.json();
        })
        .then(d => {updateDownloads(d.versions); return d.versions})
        .then(d => {
            //Automatically detect platform and set it
            const platform = (navigator.oscpu.split(' ')[0]);
            setAuto(d.find(v => v.os === platform) || null);
        })
        .catch(err => console.log(err))
    },[])
    //Don't forget to add an auto-detection field
    return(
        <Template>
            <Helmet>
                <title>[null.black] Downloads | Download the null.black VPN App</title>
                <meta
                    name="description"
                    content="Download the null.black VPN app and easily get started with our service to protect your privacy online."
                />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="English" />
                <meta name="revisit-after" content="7 days" />
                <meta name="author" content="https://null.black" />
                <link rel="canonical" href="https://null.black" />
            </Helmet>
            <header className="container">
                <h1>Downloads</h1>
                <p>We automatically detect the correct app for you based on your browser's user agent. If we got it wrong, you can pick the correct app from the list below. <Link to="/articles/how-to-connect">How to connect</Link></p>
            </header>
            <main className="container">
            {auto && //Only display this part if we do autodetect it
            <>
                <table className="Downloads">
                    <caption className="h6">Automatically Detected Platform</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="text--secondary">Platform</th>
                            <th scope="col" className="text--secondary">Version</th>
                            <th scope="col" className="text--secondary">Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Detect platform here and show the appropriate card with data from state */}
                        <DownloadCard platform={auto.os} version={auto.version} size={(parseInt(auto.size) / 1000000).toFixed(2) + "MB"} link={auto.link} />
                    </tbody>
                </table>
                <hr/><br/>
            </>}
                <table className="Downloads">
                    <caption className="h6">All Platforms</caption>
                    <thead>
                        <tr>
                            <th scope="col" className="text--secondary">Platform</th>
                            <th scope="col" className="text--secondary">Version</th>
                            <th scope="col" className="text--secondary">Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        {downloads.map(({os, version, size, link}) => {
                            return(<DownloadCard key={os} platform={os} version={version} size={(parseInt(size) / 1000000).toFixed(2) + "MB"} link={link} />)
                        })}
                    </tbody>
                </table>
            </main>
        </Template>
    )
}

export default Download;
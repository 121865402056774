import React from 'react';
import Services from '../../../Services';
import { Link } from 'react-router-dom';

import ModalCard from './ModalCard';
import Input from '../../molecules/Input/Input';
import Button from '../../molecules/Button/Button';
import { Captcha } from '../Captcha/Captcha';
import { withRouter } from 'react-router';


//When rendering, retrieve and show captcha
//When calling the API and authenticating, send captcha along with POST request 
export class Authenticate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false, //Catastrophic, unrecoverable error
            errorMessage: "",
            badInputMessage: "", //Expected errors
            attempts: 0,
            phase: props.initialPhase, //Even though you're technically not supposed to do this, this allows the user to switch between register, login, and reset without navigating and therefore preserving state in Modal, which is where the entire purchase flow is maintained.
        };
	this.setComponentDefaults();
    }
    componentDidMount = () => {
        this.setComponentDefaults();
    }
    componentDidUpdate = () => {
        this.setComponentDefaults();
    }
    handleError = (error) => {
        this.setState(state => ({...state, attempts: state.attempts + 1, error: true, errorMessage: error.toString()}))
    }
    setComponentDefaults = () => {
        this.commonInputAttributes = {
            disabled: this.state.error,
            required: true
        }
        this.commonModalCardProps = {
            error: this.state.error,
            errorMessage: this.state.errorMessage,
            backBtn: <Button buttonAttributes={{onClick: this.props.previous}}>Back</Button>,
            submitBtn: <Button type="submit" buttonAttributes={{disabled: this.state.error, form: "form"}}>Continue</Button> // TODO Specifying the form ID here is hacky, but because of the way everything is componentized, it seemed like the best way of dealing with it. Will reorganize later. 
        }
    }
    // resetAttempts = () => {
    //     this.setState({attempts: 0});
    // }
    handleRegistration = (e) => {
        e.preventDefault();
        // this.setState(state => ({attempts: state.attempts + 1}))
        Services.register(Object.fromEntries(new FormData(e.target).entries())) // Did I really need to do this lol https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
        .then(r => {
            if (r.ok) {
                return r.json()
                .then(r => {
                    //Whatever we need to do if registration is successful
                    // window.GLOBALS.loggedIn = true; //Not in use
                    // window.GLOBALS.username = r.user; //Not in use
                })
                .then(this.props.next) //Callback to Modal to move to the next screen. This can cause a memory leak if anything that happens after this tries to update state asynchronously.
            } else {
                return r.json().then(d => {
                    //Handle expected errors here
                    this.setState({badInputMessage: d.error})
                })
            }
        })
        .catch(this.handleError)
    }
    handleLogin = (e) => {
        e.preventDefault();
        console.log("Logging in");
        // this.setState(state => ({attempts: state.attempts + 1}))
        Services.login(Object.fromEntries(new FormData(e.target).entries()))
        .then(r => {
            if (r.ok) {
                //*Hacker voice* I'm in
                return r.json()
                .then(response => {
                    // window.GLOBALS.loggedIn = true; //Not in use
                    // window.GLOBALS.username = response.user; //Not in use
                })
                .then(this.props.next)
            } else {
                return r.json().then(d => {
                    this.setState({badInputMessage: d.error})
                })
            }
        })
        .catch(this.handleError)
    }

    handleForgotPassword = (e) => {
        e.preventDefault();
        this.setState(state => ({attempts: state.attempts + 1}))
        return Services.forgotPass(Object.fromEntries(new FormData(e.target).entries()))
        .then(r => {
            if (r.ok) {
                //Go to login
                this.setState({phase: "login"})
            } else {
                return r.json().then(d => {
                    this.setState({badInputMessage: d.error})
                })
            }
        })
        .catch(this.handleError)
    }

    handleResetPassword = (e) => {
        e.preventDefault();
        const inputData = Object.fromEntries(new FormData(e.target).entries());
        //Validate that the 
        if (inputData.password !== inputData.password2) {
            this.setState({badInputMessage: "Make sure you confirm your password correctly."});
            return;
        }
        return Services.resetPass(inputData)
        .then(r => {
            if (r.ok) {
                this.props.next();
            } else {
                return r.json().then(d => {
                    this.setState({badInputMessage: d.error})
                })
            }
        })
        .catch(this.handleError)
    }

    render() {
        switch (this.state.phase) {
            case "register": {
                //TODO THIS NEEDS TO BE COMPONENTIZED OH MY GOD
                //Probably break each of these out into a separate component that handles API stuff on its own - Authenticate probably isn't necessary. 
                //Honestly maybe I just shouldn't use my Input component, it's just making it harder to read
                return(
                    <ModalCard title="Register"
                    subtitle={<>Register or <button type="button" className="lookLikeLink" onClick={() => this.setState({phase: "login"})}>login</button> instead</>}
                    actionMessage={this.state.actionMessage}
                    {...this.commonModalCardProps}
                    >
                    <form id="form" onSubmit={this.handleRegistration}>
                        <Input type="email" label="Enter your email address" subtext="You can verify your email after checkout" inputAttributes={{ ...this.commonInputAttributes, name: "email", placeholder:"Email" }}/>
                        <Input type="text" label="Enter your username" inputAttributes={{ ...this.commonInputAttributes, name: "username", placeholder: "Username" }}/>
                        <Input type="password" label="Set a password" subtext="Your password must be at least 8 characters" inputAttributes={{ ...this.commonInputAttributes, name: "password", placeholder: "Password", minLength: 8, title: "At least 8 characters" }}/>
                        <Captcha onError={this.handleError} attempts={this.state.attempts} resetAttempts={this.resetAttempts} service="register" inputAttributes={this.commonInputAttributes}  />
                        <Input type="checkbox" label="Terms and Privacy" inputAttributes={{...this.commonInputAttributes, onChange: null}} subtext={<>I agree to the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.</>} />
                    </form>
                    {this.state.badInputMessage && <span className="ModalCard-body-expected-error text--secondary text--danger">Error: {this.state.badInputMessage}</span>}
                    </ModalCard>
                );
            }
            case "login":
                return(
                    <ModalCard title="Login"
                    subtitle={<>Log in or <button type="button" className="lookLikeLink" onClick={() => this.setState({phase: "register"})}>register</button> instead</>}
                    actionMessage={this.state.actionMessage}
                    {...this.commonModalCardProps}
                    >
                    <form id="form" onSubmit={this.handleLogin}>
                        <Input type="text" label="Enter your username" inputAttributes={{ ...this.commonInputAttributes, name: "username", placeholder:"Username" }}/>
                        <Input type="password" label="Enter your password" subtext={<button className="lookLikeLink" onClick={() => {this.setState({phase: "forgot"})}}>Forgot your password?</button>} inputAttributes={{ ...this.commonInputAttributes, name: "password", placeholder:"Password" }}/>
                        <Captcha onError={this.handleError} attempts={this.state.attempts} resetAttempts={this.resetAttempts} service="login" inputAttributes={this.commonInputAttributes} />
                    </form>
                    {this.state.badInputMessage && <span className="ModalCard-body-expected-error text--secondary text--danger">Error: {this.state.badInputMessage}</span>}
                    </ModalCard>
                )
            case "forgot":
                //email only
                return(
                    <ModalCard title="Forgot Password"
                    subtitle={<><button type="button" className="lookLikeLink" onClick={() => this.setState({phase: "register"})}>Register</button> or <button type="button" className="lookLikeLink" onClick={() => this.setState({phase: "login"})}>login</button> instead</>}
                    actionMessage={this.state.actionMessage}
                    {...this.commonModalCardProps}
                    >
                    <form id="form" onSubmit={this.handleForgotPassword}>
                        <Input type="email" label="Enter your email address" inputAttributes={{ ...this.commonInputAttributes, name: "email", placeholder:"Email" }}/>
                        <Captcha onError={this.handleError} attempts={this.state.attempts} resetAttempts={this.resetAttempts} service="email" inputAttributes={this.commonInputAttributes} />
                    </form>
                    {this.state.badInputMessage && <span className="ModalCard-body-expected-error text--secondary text--danger">Error: {this.state.badInputMessage}</span>}
                    </ModalCard>
                )
	        case "reset":
                const resetParams = new URLSearchParams(this.props.location.search);
                return (
		            <ModalCard title="Reset Password"
                    subtitle={<> Remembered your password? <button type="button" className="lookLikeLink" onClick={() => this.setState({phase: "login"})}>Login</button> instead</>}
                    actionMessage={this.state.actionMessage}
                    {...this.commonModalCardProps}
                    >
                    <form id="form" onSubmit={this.handleResetPassword}>
                        {/* 
                        //Grab query string params from URL, as set in reset link sent via email
                        //Must have username included for hashing
                        */}
                        <Input type="text" label="Username" inputAttributes={{ ...this.commonInputAttributes, readOnly: true, value: resetParams.get("username"), name: "username", placeholder:"Username" }} /> {/* This input must be read-only rather than disabled so that it gets sent along with the form */}
                        <input type="hidden" value={resetParams.get("token")} name="token" />
                        <Input type="password" label="Set your new password" inputAttributes={{ ...this.commonInputAttributes, minLength: 8, autoComplete: "new-password", name: "password", placeholder:"New Password" }}/>
                        <Input type="password" label="Confirm your new password" inputAttributes={{ ...this.commonInputAttributes, autoComplete: "new-password", minLength: 8, name: "password2", placeholder:"Confirm New Password" }}/>
                    </form>
                    {this.state.badInputMessage && <span className="ModalCard-body-expected-error text--secondary text--danger">Error: {this.state.badInputMessage}</span>}
                    </ModalCard>
                )
            default:
                return null;
        } 
    }
}

export default withRouter(Authenticate);

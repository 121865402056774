import React from 'react';
import './Modal.scss';
import { Link, Redirect } from 'react-router-dom';
import { Authenticate } from './Authenticate';
import { Purchase } from './Purchase';
import { Redeem } from './Redeem';
export class Modal extends React.Component {
    // So this thing is gonna manage all state and sequence control
    // for the purchase and authentication flows 
    constructor(props) {
        super(props);
        this.state = {
            currentScreenIndex: (window.parseCookie().UserSesh ? 1 : 0) //Skip to the next step if already logged in
        };
        this.commonProps = {
            previous: this.previous,
            next: this.next
        }
        console.log(props.match);
        const params = new URLSearchParams(this.props.location.search);
         this.product = {monthly: 574820, yearly: 574821}[params.get("plan")] || 574820; //TODO Make sure we switch to gifting properly and make proper use of the URL matching.
        //this.product = {monthly: 642532, yearly: 642533}[params.get("plan")] || 642532; //For alpha testing
        this.gift = params.get("gift");

        this.flows = {
            register: [
                //TODO passing next() into all of these is super lame so I should probably accomplish this with like a HOC or something
                <Authenticate initialPhase="register" location={this.props.location} {...this.commonProps} />,
                <Redirect to="/dashboard" />
            ],
            login: [
                <Authenticate initialPhase="login" {...this.commonProps} />,
                <Redirect to="/dashboard" />
            ],
            forgot: [
                <Authenticate initialPhase="forgot" {...this.commonProps} />,
                <Authenticate initialPhase="login" {...this.commonProps} />
            ],
            reset: [
                <Authenticate initialPhase="reset" location={this.props.location} {...this.commonProps} />,
                <Redirect to="/dashboard" />
            ],
            purchase: [ //country, zip, email address
                //auth
                <Authenticate initialPhase="register" {...this.commonProps} />,
                //Country
                //TODO Although we'd like to collect this information with our own UI and pass it in, Paddle can handle this for us by default so it's not a high priority 
                // <Purchase phase="selectCountry" {...this.commonProps} />,
                //payment - paddle handles all this shit, thank god
                <Purchase product={this.product} phase="payment" {...this.commonProps} />,
                <Redirect to="/dashboard" />
            ],
            gift: [ //country, zip, email
                //auth
                <Authenticate initialPhase="login" {...this.commonProps} />,
                //country
                <Purchase phase="selectCountry" {...this.commonProps} />,
                //show the code on screen and say "if you have an email address on your account, you will recieve an email with this code"
                <Purchase product={this.product} phase="payment" {...this.commonProps} />,
                <Redirect to="/dashboard" />
            ],
            redeem: [ //gift code
                //auth
                <Authenticate initialPhase="login" {...this.commonProps} />,
                <Redeem {...this.commonProps} />,
                <Redirect to="/dashboard" />

            ]
        }
    }
    next = () => {
        //When this function is called, it means whatever action the current ModalCard had to complete was successful, so increment state.currentScreenIndex to go to the next screen.
        this.setState(state => ({...state, currentScreenIndex: state.currentScreenIndex + 1}));
    }
    previous = () => {
        //Called whenever the back button is pressed or the user somehow ends up on a screen that they haven't properly authenticated for or provided data for
        if (this.state.currentScreenIndex === 0) {
            this.props.history.push(".");
            return true;
        }
        this.setState(state => ({...state, currentScreenIndex: state.currentScreenIndex - 1}))
    }
    render() {
        return(
            <div className="Modal">
                <Link to={"/" + new URL(window.location.href).pathname.split('/')[1]}>
                    <div className="Modal-splash"></div>
                </Link>
                {this.flows[this.props.match.params.flow][this.state.currentScreenIndex]}
            </div>
        )
    }
}

//TODO When implementing loading stuff, be sure to implement action messages to show over the loading screen.
Modal.defaultProps = {
    actionMessage: "creating your account"
}
export default Modal;

import React from 'react';
import {
  Route, useRouteMatch,
} from 'react-router-dom';

// import PropTypes from 'prop-types';

// import LoadingScreen from '../organisms/LoadingScreen/LoadingScreen';
import Nav from '../organisms/Nav/Nav';
import Footer from '../organisms/Footer/Footer';
import Modal from '../organisms/Modal/Modal';

// import './Template.scss'; //Empty file lol


export const Template = (props) => {
    return(
        <>
            <Nav />
            {/* <LoadingScreen reveal />  TODO add <Suspense /> for loading screens -> https://web.dev/code-splitting-suspense/?utm_source=lighthouse&utm_medium=devtools */}
            <Route path={`${useRouteMatch().url}/:flow(register|login|forgot|reset|purchase|gift|redeem)`} component={Modal} />
            {props.children}
            <Footer />
        </>
    );
}

export default Template;
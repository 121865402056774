export class Services {
    //The main class for interacting with the null.black API
    //TODO Organize these into subclasses 
    //TODO set credentials: "omit" to omit cookies from request and response as necessary.

    /*


    BASE FUNCTIONS


    */
    static async _callAPI(endpoint, options = { method: "GET" }) { //Setting a default in case we don't pass one in elsewhere
        //DO NOT FUCKING CALL THIS FROM OUTSIDE THIS CLASS UNTIL JS PROPERLY IMPLEMENTS PRIVATE FUNCTIONS WHEN YOU WILL NO LONGER BE ABLE TO
        if (options.method === "POST" && options.body) {
            options = {
                ...options,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                body: new URLSearchParams(options.body), //This is weird but like whatever yo https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
                credentials: "include", //credentials: "same-origin", TODO change to this for prod
            }
        }
        //TODO add credentials: "same-origin" on a method-by-method basis as needed, instead of here.
        //const origin = "http://localhost:3000"; //TODO change this to the proper API origin
	const origin = "/api/v1";
        return await Promise.race([
            //Basically this returns the first promise that resolves - either a 3 second timeout, or the API response.
            fetch(origin + endpoint, {credentials: 'include', ...options}),
            new Promise((_,reject) => 
                setTimeout(() => reject(new Error('Timeout while trying to connect to service')), 5000)
            )
        ])
        .catch(e => {
            //Catch general network or other unexpected errors here, and allow components to deal with expected errors (e.g. Username taken) on their own.
            //TODO This should be dealt with better, but doing conditional error handling is a pain in the fuKCINBG ASS OH MY GOD
            throw e;
        })
    }
    static async _sha512(str) {
        //https://stackoverflow.com/questions/55926281/how-do-i-hash-a-string-using-javascript-with-sha512-algorithm#55926440
        //Arguably not the best idea to get your cryptographic algorithms from SO, but who's stopping me >:D
        //EDIT turns out this works, or at least it produces the same hashes that the implementation used on the old site did
        const buf = await crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str));
        return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
    }
    /*


    AUTHENTICATION RELATED


    */
    //Options are passed in as an object in any method that is called directly by a form submit handler, since the options are passed in like register(Object.fromEntries(new FormData(e.target).entries()))

    static async getUser(username = "_") { //Get currently logged in user data by default
        return await this._callAPI(`/users/${username}`);
    }

    static async register({email, username, password, captchaResponse = ""}) {
        //Email will eventually be optional
        //TODO Remember to regex these ahead of time
        //Hash password here
        return await this._callAPI(
            `/users/${username}/register`,
            { 
                method: "POST",
                body: {
                    email: email,
                    hash: await this._sha512(username + password),
                    captcha: captchaResponse
                }
            }
        )
    }
    static async login({username, password, captchaResponse = ""}) {
        //Using object syntax here for named arguments. Only used in places where some arguments are optional
        return await this._callAPI(
            `/users/${username}/login`,
            {
                method: "POST",
                body: {
                    username: username,
                    hash: await this._sha512(username + password),
                    captcha: captchaResponse
                }
            }
        )
    }
    static async forgotPass({email, captchaResponse = ""}) {
        return await this._callAPI(
            `/users/_/forgot`,
            {
                method: "POST",
                body: {
                    email: email,
                    captcha: captchaResponse
                }
            }
        )
    }
    static async resetPass({username, password, token}) {
        //Called it "reset" instead of "rset", fuck your naming convention Dhruv
        return await this._callAPI(
            `/users/${username}/rsetpass/${encodeURIComponent(token)}`,
            {
                method: "POST",
                body: {
                    hash: await this._sha512(username + password)
                }
            }
        )
    }
    static async getCaptcha(service) {
        //Service is one of: login || register || email(for forgot password)
        return await this._callAPI(`/captchainfo/${service}`);
    }

    /*


    BILLING RELATED


    */

    static async genPaymentToken(username = '_') {
        return await this._callAPI(`/users/${username}/gen_payment_token`);
        // aaaaaaaaaaahahahaha dhruv I'm parsing the token aaaaaaaaaahahahahahahahahahahahahahahahaha
        // JSON.parse(token);
    }
    static async getUserBilling(username = '_') {
        return await this._callAPI(`/users/${username}/billing`);
    }
    /*


    GIFTING RELATED


    */

    static async listAvailableCodes(username = '_') {
        return await this._callAPI(`/users/${username}/list_giftcodes`);
    }
    static async claimCode({username = '_', code}) {
        return await this._callAPI(
            `/users/${username}/claim_code`,
            {
                method: "POST",
                body: {
                    code: code
                }
            }
        );
    }

    /*


    VPN RELATED


    */

    static async getServerData() {
        //
        return await this._callAPI("/servers");
    }
    static async getTickers() {
        //
        return await this._callAPI("/ticker");
    }
    static async getUserConnections(username = '_') {
        return await this._callAPI(`/users/${username}/connections`);
    }
    static async getDownloads(project) {
        //Gets a list of app download
        return await this._callAPI(`/updates/brief/${project}`);
    }
}

export default Services;

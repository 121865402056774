import React from 'react';
import './Dial.scss';

export const Dial = (props) => {
    const multipliers = {
        "KB": [1, 1000],
        "MB": [1000, 1000000],
        "GB": [1000000, 1000000000],
        "TB": [1000000000, 1000000000000],
    };
    const selectedMultiplers = multipliers[props.unit];
    let [display, rotation] = [
        (Math.floor(props.value / selectedMultiplers[0]) % 1000),
        (Math.floor(360 * props.value / selectedMultiplers[1]))
    ];
    // console.log("rotation at ",rotation); wtf dhruv, this almost crashed my browser
    return(
        <div className="Dial Dial-numbers">
            <span className="Dial-counter h2">
                <span className="Dial-counter-value">{Math.floor(display).toLocaleString('en-US', { minimumIntegerDigits: 3, useGrouping: false })}</span>
                <span className="Dial-counter-unit">{props.unit}</span>
            </span>
            <span className="Dial-face-label text--secondary">{props.unit} protected<br/>over all time</span>
            {/* Add this style attr after retrieving the appropriate data from the API and calculating deg */}
            <div className="Dial-hand" style={{transform: `rotate(${rotation}deg)`}}></div>
        </div>
    );
}
Dial.defaultProps = {
    value: 0.00,
    unit: "TB"
}
export default Dial;

import React from 'react';
import PropTypes from 'prop-types';
import './Input.scss';

export const Input = (props) => {
    return (
        <label className="Input">
            {props.label}
            <br />
            {props.substitute || <input type={props.type} className={`input-item--${props.type}`}  {...props.inputAttributes} />}
            {props.subtext && <p className="text--secondary">{props.subtext}</p>}
        </label>
    );
}


Input.defaultProps = {
    type: 'text'
}
Input.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string
};

export default Input;
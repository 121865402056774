import React from 'react';
import './DownloadCard.scss';
export const DownloadCard = (props) => {
    const icons = {
        "Windows":"windows",
        "MacOS":"apple",
        "iOS":"apple",
        "Android":"android",
        "Linux":"ubuntu"
    }
    const downloadIcon = (new URL(props.link).hostname === "null.black") ? "download" : "share";
    return(
        <tr className="DownloadCard">
            <td>
                <i className={`icon icon-${icons[props.platform]}`}></i>
                <span className="platform-name">
                {props.platform}
                </span>
            </td>
            <td>{props.version}</td>
            <td>{props.size}</td>
            <td>
                <a href={props.link} className="no-decorate" target="_blank" rel="noopener noreferrer">
                    <span className="download-text">Download</span>
                    <i className={`icon icon-${downloadIcon}`}></i>
                </a>
            </td>
        </tr>
    );
}
export default DownloadCard;
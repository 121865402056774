//TODO Add lazy loading with React.lazy and add loading screen. Make sure to preload any resources needed as necessary, and we should probably optimize CSS to ensure only what's needed is loaded at any given time. https://web.dev/code-splitting-suspense/?utm_source=lighthouse&utm_medium=devtools
// import React, { Suspense, lazy } from 'react';
import './globals';
import './globalStyles.scss';

import Status from './stories/pages/Status';
import Home from './stories/pages/Home';
import Dashboard from './stories/pages/Dashboard';
import Download from './stories/pages/Download';
import Articles from './stories/pages/Article';
//import PasswordReset from './stories/pages/PasswordReset';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/article/:article" component={Articles} />
        <Route strict path="/dashboard" component={Dashboard} />
        <Route strict path="/download" component={Download} />
        <Route strict path="/home" component={Home} />
        <Route strict path="/status" component={Status} />
        <Route path="/">
          {/* Routes with no path="" always match */}
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

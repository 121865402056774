import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Template from "../templates/Template";
import './Home.scss';
import { Link } from 'react-router-dom';
import Button from '../molecules/Button/Button';
import Dial from '../organisms/Dial/Dial';
import Card from '../organisms/Card/Card';
import PriceCard from '../organisms/PriceCard/PriceCard';
import Services from '../../Services';
export const Home = (props) => {
    let [ticker_state,set_ticker_state] = useState({ initial_kb: 0,
	    target_delta_kb: 0,
	    duration: 0
    })
	
    const [delta_dial_kb,update_delta_dial_kb] = useState(0);
    const [locationCount, updateLocationCount] = useState(3);
    const [load_ready, set_load_ready] = useState(true);

    useEffect(() => {
	if(load_ready)
	{
	    set_load_ready(false);
            Services.getTickers()
            .then(r => {
                if (r.ok) {
                    return r.json()
                } else {
                    throw new Error("Couldn't reach servers API");
                }
            })
            .then(data => {
                delete data.status;
	        set_ticker_state({initial_kb: data.ticker_start_kb, target_delta_kb: data.ticker_stop_kb - data.ticker_start_kb, duration: data.ticker_duration_secs})
		console.log("scheduling next interpolation fetch to be ",data.ticker_duration_secs," seconds from now")
		setTimeout(() => {
			console.log("setting load to ready!")
			set_load_ready(true);
		}, 1000*data.ticker_duration_secs)
	    	
                // update location count 
                updateLocationCount(data.total_servers);

            }) 
            .catch(err => { console.log(err) })
	}
    }, [load_ready])

    useEffect(() => {
	//  console.log("has interval");
         let update_count = 0;
         let interval = setInterval(() => {
            if(update_count <= ticker_state.duration*10) {
		    update_delta_dial_kb((update_count*ticker_state.target_delta_kb/(ticker_state.duration*10)));
		    update_count++;
	    }
	},100);
	return () => clearInterval(interval);
    },[ticker_state])

    // console.log("render: initial_kb", initial_kb)
    // console.log("render: target_delta_kb", target_delta_kb)

    let unit_counts = ["TB","GB","MB"];
    let headliner_count = Math.floor((ticker_state.initial_kb+delta_dial_kb)/1000000000);

    return(
        <Template>
            <Helmet>
                <title>[null.black] The Secure and Affordable VPN Service</title>
                <meta
                    name="description"
                    content="null.black is the secure, reliable and affordable VPN service that's purpose-built to protect your privacy online, while supporting freedom around the globe."
                />
                <meta name="robots" content="index, follow" />
                <meta name="language" content="English" />
                <meta name="revisit-after" content="14 days" />
                <meta name="author" content="https://null.black" />
                <link rel="canonical" href="https://null.black" />
            </Helmet>
            <header className="Home-header">
                {/* TODO Add the curvey wavy clippy thingy */}
                <img src="/protest.webp" alt="Calvin Lwin of null.black Inc. at a protest against the ongoing military coup in Burma" className="Home-header-background" decoding="async" />
                <div className="Home-header-content container">
                    <h1>The quintessential VPN, delivering privacy for all.</h1>
                    <a href="/article/about-us#mission" className="always-decorate">We stand with Myanmar.</a>
                    <br />
                    <a href="#pricing" className="no-decorate">
                        <Button>Buy Now</Button>
                    </a>
                </div>
            </header>
            <main className="Home-content container">
                <section id="stats" className="Home-section">
                    <h2>{headliner_count} {unit_counts[0]} <span className="text--primary">of traffic protected</span></h2>
                    <div className="card-container">
                        {unit_counts.map((unit) => 
                            <Dial key={unit} value={ticker_state.initial_kb+delta_dial_kb} unit={unit} />
                        )}
                    </div>
                </section>
                <section id="features" className="Home-section">
                    <h2>Features</h2>
                    <div className="card-container">
                        <Card icon={<i className="icon icon-admin"></i>} title="No Tracking" footerContent={<a  href="/article/about-us#mission"><Button>Our Mission</Button></a>}>
                            Just because you have nothing to hide doesn’t mean anyone needs to be looking. null.black doesn’t track users. Our VPN is designed for privacy and security. <Link to="/privacy">Visit our privacy policy</Link>{/* or <Link to="/privacy">view our log files directly</Link>*/} to see what information we do collect and why.
                        </Card>
                        <Card icon={<i className="icon icon-dollar"></i>} title="Affordable Price" footerContent={<a href="#pricing"><Button>Pricing</Button></a>}>Many mainstream commercial VPNs charge exhorbitant prices for their service. The truth is that VPNs are very inexpensive to operate. We provide a very affordable service with zero commitment. {/* , and a 30-day free trial to boot. */}</Card>
                        <Card icon={<i className="icon icon-team"></i>} title="We Support Democracy" footerContent={<a href="/article/about-us#history"><Button>Our History</Button></a>}>We proudly stand with and support the citizens of Myanmar in their pursuit of a civilized democratic government. Read more about what we’re doing to provide free and open internet access to those in need.
                        </Card>
                    </div>
                </section>
                <section id="pricing" className="Home-section">
                    <h2>Plans</h2>
                    <div className="card-container">
                        <PriceCard features={["Up to 5 devices", `${locationCount} Locations Worldwide`, "No Commitment"]} />
                        <PriceCard yearly features={["Up to 5 devices", `${locationCount} Locations Worldwide`, "Reduced Rate"]} />
                    </div>
                    <article>
                        <h3>How do we keep our prices so low?</h3>
                        <p>Many mainstream commercial VPNs charge exorbitant prices for their service. The truth is that VPNs are very inexpensive to operate. We don’t spend money on expensive promotional campaigns, include unnecessary extra features in our software or overspend on infrastructure.</p>
                    </article>
                </section>
            </main>
        </Template>
        )
}


export default Home;

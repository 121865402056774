import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import './Nav.scss';
import Logo from '../../molecules/Logo/Logo';
import { Dropdown } from '../../molecules/Dropdown/Dropdown';
import { Link , useRouteMatch } from 'react-router-dom';
export const Nav = (props) => {
    const [expanded, toggleExpand] = useState(false);
    const match = useRouteMatch();
    const iCanHazCookie = window.parseCookie().UserSesh; //Whether or not we have an auth cookie
    
    return (
        <div className={`Nav expandable ${expanded ? "expanded" : ""}`}>
            <nav>
                <ul className="Nav-items container">
                    <li className="Nav-item--logo">
                        <span className="Nav-item-label" href="/"><Logo /></span>
                    </li>
                    <li className="Nav-item--home">
                        <Link className="Nav-item-label" to="/">Home</Link>
                    </li>
                    {/* Iterate over the nav content JSON object (props.content) and generate a dropdown menu for each item. */}
                    {Object.entries(props.content).map(([label, links]) => (
                    <li key={label} className="Nav-item">
                        <Dropdown label={label} links={links} />
                    </li>
                    ))}
                    <li className="Nav-item--login">
                    {/* Show log out link when authenticated and at the dashboard, show link to Dashboard when logged in but not at the dashboard, and show a sign up link if not authenticated  */}
                        <a className="Nav-item-label" href={(iCanHazCookie) ? ((match.url === "/dashboard") ? "/logout" : "/dashboard") : `${match.url}/register`}>
                        {(iCanHazCookie) ? (match.url === "/dashboard" ? <><i className="icon icon-logout"></i>&nbsp;Log Out</> : "Dashboard") : "Sign In/Up"}
                        </a>
                    </li>
                </ul>
                <span className="Nav-item--hamburger h3" onClick={() => toggleExpand(!expanded)}>
                    &equiv;
                </span>
            </nav>
        </div>
    );
}
// TODO make sure all these URLs are correct
Nav.defaultProps = {
    content: {
        "Product": {
            "Plans":"/home#pricing",
            "Gift Cards":"/home#pricing",
            "Features":"/home#features",
            // "Status":"/status", //TODO Not in scope for 31st, implement later
        },
        "Help":{
            "Support Chat":"javascript:window.GLOBALS.openSupportChat()", //This is fuckin stupid lol
            "How to Connect":"/article/how-to-connect",
            "Download":"/download",

        },
        "Company":{
            "About Us":"/article/about-us",
            "Company Information":"/article/about-us",
            "Terms of Service":"/terms", 
            "Privacy Policy":"/privacy",
        },
        "Social":{
            "Instagram":"/instagram",
            "Twitter":"/twitter",
            "Discord":"/discord",
        }
    }
};
Nav.propTypes = {};
export default Nav;
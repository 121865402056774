import React from 'react';
import { Link } from 'react-router-dom';
import Services from '../../../Services';

import ModalCard from './ModalCard';
// import Input from '../../molecules/Input/Input';
// import Select from '../../molecules/Input/Select';
import Button from '../../molecules/Button/Button';
import { Paddle } from '../Paddle/Paddle';

export class Purchase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: "",
            expectedError: "",
            email: "",
            vendor: "",
            // country: "",
            token: "",
        };
    }
    componentDidUpdate() {
        this.commonModalCardProps = {
            loading: this.state.loading,
            error: this.state.error,
            errorMessage: this.state.errorMessage,
            backBtn: <Button buttonAttributes={{onClick: this.props.previous}}>Back</Button>,
            submitBtn: <Button type="submit" buttonAttributes={{disabled: this.state.error, form: "form" }}>Continue</Button>
        }
        this.commonInputAttributes = {
            required: true,
            disabled: this.state.error,
        }
    }
    componentWillUnmount() {
        //Hacky workaround to prevent memory leak which occurs when trying to set state on an unmounted component.  
        this.handleCatchAll = true;
        window.onPaddleSuccess = true;
        window.onPaddleClose = true;
        window.onPaddleLoad = true;
    }
    componentDidMount() {
        if (!window.parseCookie().UserSesh) {
            //Go back a frame
            this.props.previous();
            return;
        }
        //Call API to get email address and subscription status
        Services.getUser()
        .then(r => {
            if (r.ok) {
                return r.json().then(d => 
                    this.setState({email: d.email, vendor: d.vendor})
                )
            } else {
                return r.json().then(d => 
                    this.setState({expectedError: d.error})
                )
            }
        })

        //Check that we can actually proceed

        //Call API to get Paddle token
        Services.genPaymentToken()
        .then(r => {
            if (r.ok) {
                return r.json().then(d => 
                    this.setState({token: d.token})
                )
            } else {
                return r.json().then(d => 
                    this.setState({expectedError: d.error})
                )
            }
        })
    }
    handleError = (error) => {
        this.setState(state => ({...state, error: true, errorMessage: error.toString()}))
    }
    countrySelectionHandler = (e) => {
        e.preventDefault();
        this.props.next();
    }
    render() {
        //TODO add the option to switch between monthly and yearly in this UI somewhere
        
        if (this.state.vendor !== "none") {
            //Redirect to dashboard if they already have a subscription
            return (
                <ModalCard title="Checkout">
                    <span>It looks like you already have a subscription with null.black.<br/><Link to="/dashboard">Return to your dashboard</Link>.</span>
                </ModalCard>
            )
        }
        //TODO check for all other conditions for required information for each slide
        switch (this.props.phase) {
            /*case "selectCountry": {

                //TODO skipping this for now since Paddle can do it for us

                if (this.state.country) {
                    //If we already have a country in state (from a previous purchase attempt or something), skip this step
                    this.props.next();
                }
                return(
                    <ModalCard 
                    title="Select your Country"
                    subtitle="Please select your country"
                    onSubmit={this.countrySelectionHandler}
                    {...this.commonModalCardProps}
                    >
                    <Input label="Select your country" substitute={
                        <Select
                        selectAttributes={{...this.commonInputAttributes, value: this.state.country, }}
                        options={{
                            "USA":"usa",
                            "UK":"uk",
                            //TODO Fill this in
                        }} />
                    } />
                    </ModalCard>
                );
            }*/
            case "payment":
                //This has to be done because Paddle requires the function name is passed in as a string and that is fucking annoying 
                //window.onPaddleLoad = () => {this.setState(state => ({...state, loading: false}))}; //TODO Fix this by reorganizing components - This doesn't work because it updates state, which re-renders the entire thing and therefore makes paddle load again and it loops infinitely
                window.onPaddleSuccess = () => { window.location = "/dashboard" };
                window.onPaddleClose = () => { window.location = "." };
                return(
                    <ModalCard 
                    title="Payment"
                    subtitle="Enter your payment information through our secure gateway."
                    actionMessage="finalizing payment"
                    >
                    {/* TODO Collect country and zip code ourselves */}
                    <Paddle productId={this.props.product} email={this.state.email} token={this.state.token} onPaddleSuccess="onPaddleSuccess" onPaddleClose="onPaddleClose" />
                    </ModalCard>
                );
            default:
                return null;
        } 
    }
}

export default Purchase;

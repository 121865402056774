import React from 'react';
import './Logo.scss';

//TODO create a loading screen organism
//which wraps this and handles async loading
export class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.containerClasses = {
            "box":"Logo-box",
            "fill":"Logo-fill"
        }
        this.textClasses = {
            "expand":"Logo-text-expand",
            "stack":"Logo-text-stack",
            "animate":"Logo-text-animate"
        }
    }
    propsToClasses(props, propClasses) {
        let className = "";
        for (const [propName, propClass] of Object.entries(propClasses)) {
            if (props[propName]) {
                className += ' ' + propClass;
            }
        }
        return className;
    }
    render() {
        return(
            <span className={`Logo ${this.propsToClasses(this.props,this.containerClasses)} h3--bold`}>
                <span className={`Logo-text ${this.propsToClasses(this.props,this.textClasses)}`}>
                    <span className="Logo-name null">null.</span>
                    {this.props.stack && <br />}
                    <span className="Logo-name black">black</span>
                </span>
            </span>
        );
    }
}
export default Logo;
import React from 'react';
// import PropTypes from 'prop-types';
import './Card.scss';

export const Card = (props) => {
    return(
        <div className="Card">
            {props.title && //Only show the header if a title is specified
            <div className="Card-header">
                <h3 className="Card-header-title h6--bold">
                {props.icon && //Only show icon if one is specified
                    <span className="Card-header-icon icon">{props.icon}</span>}
                    {props.title}
                </h3>
            </div>
            }
            <div className="Card-body">
                <div className="Card-body-content">{props.children}</div>
                {props.footerContent && 
                <div className="Card-body-footer">
                    {props.footerContent}
                </div>
                }
            </div>
        </div>
    );
}
Card.defaultProps = {
    icon: "",
    children: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore animi odit cum id quasi earum doloremque libero! Eaque fuga a veniam minima modi exercitationem! Quis illum explicabo iste asperiores impedit corrupti ab reiciendis laboriosam esse molestiae. Blanditiis alias vero itaque reiciendis, tenetur odit nisi cum tempora necessitatibus omnis enim saepe?"
};
export default Card;

import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';
import { Link } from 'react-router-dom';
export const Button = (props) => {
    const btn = <button type={props.type} className="Button text--mono" {...props.buttonAttributes}>
                    {props.icon &&
                        <span className="Button-icon">
                            {props.icon}
                        </span>
                    }
                    {props.children || props.label}
                </button>;
    return(props.href ? <Link to={props.href}>{btn}</Link> : btn);
}

Button.propTypes = {
    children: PropTypes.string,
    icon: PropTypes.string
};
Button.defaultProps = {
    type: "button"
}
export default Button;